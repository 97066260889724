import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../layout';
import TitleHeader from '../layout/title-header';

import { FaArrowRight } from 'react-icons/fa';

import LangLink from '../components/lang-link';
import SEO from '../components/seo';

import HomlanPattern from '../images/homlan-pattern.svg'

const Services = (props) => {
    const pageData = props.data.prismicServicesPage.data;
    const lang = props.data.prismicServicesPage.lang;
    const services = props.data.allPrismicService.nodes.map(({ data }) => data);
    return (
        <Layout lang={lang}>
            <SEO
                title={pageData.title}
                keywords={pageData.keywords.map(({ keyword }) => keyword)}
                lang={props.data.prismicServicesPage.lang}
            />
            <article id="services-page">
                <div id="services-header-container">
                    <TitleHeader title={pageData.title} background={HomlanPattern} />
                </div>
                <ul id="services-section" className="remove-list-styles">{
                    services.map(service =>
                        <li className="service-card-container" key={service.title}>
                            <LangLink className="service-card-link" to={service.link} lang={lang}>
                                <ServiceCard
                                    title={service.title}
                                    description={service.description.html}
                                    icon={service.icon}
                                    moreText={pageData.more_link_text} />
                            </LangLink>
                        </li>
                    )}
                </ul>
            </article>
        </Layout>
    )
}

export default Services;

class ServiceCard extends React.Component {
    render() {
        const { title, description, icon, moreText } = this.props;
        return (
            <article className="service-card responsive-paddings--horizontal">
                <img className="service-card__icon" src={icon.url} alt={icon.alt} />
                <h2 className="service-card__title">{title}</h2>
                <section className="service-card__description"
                    dangerouslySetInnerHTML={{ __html: description }} />
                <span className={`service-card__more-link`}>
                    {moreText}
                    <span className="service-card__more-link__icon align-center-fa"><FaArrowRight /></span>
                </span>
            </article>
        )
    }
}

export const query = graphql`
    query($slug: String!, $lang: String!) {
        prismicServicesPage(fields: { slug: { eq: $slug } }) {
            lang
            data {
                title
                more_link_text
                keywords {
                    keyword
                }
            }
        }
        allPrismicService(filter: { lang: { eq: $lang }}) {
            nodes {
                data {
                    title
                    description {
                        html
                    }
                    icon {
                        url
                        alt
                    }
                    link
                }
            }
        }   
    }`;